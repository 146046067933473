import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import * as constants from "./constants";
import { getRelativeTime } from "./helper";

// Firebase setup
const firebaseConfig = {
  apiKey: "AIzaSyCOhjUQltW6bYYGUNt5p1o7_HCuuhKoE4Y",
  authDomain: "chatbotstudy-e8941.firebaseapp.com",
  projectId: "chatbotstudy-e8941",
  storageBucket: "chatbotstudy-e8941.appspot.com",
  messagingSenderId: "783520905831",
  appId: "1:783520905831:web:accb9926105a551bdab2c2",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

/**
 * Fetches the ChatGPT API KEY from Firebase
 *
 * @async
 * @function
 * @param {string} passcode - A passcode used get access to the API key
 * @returns {Promise<string>} A Promise that resolves with the retrieved API key if the passcode is correct,
 *                            otherwise resolves with "Wrong passcode" error message.
 */
const get_api_key = async (passcode) => {
  var api_key_request = await fetch(
    "https://helloworld-ii7nvddrfa-uc.a.run.app/",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: passcode,
      }),
    }
  );
  var key_reader = api_key_request.body.getReader();
  const key_decoder = new TextDecoder("utf-8");
  var api_key = "";
  while (true) {
    const { done, value } = await key_reader.read();
    if (done) {
      break;
    }

    api_key += key_decoder.decode(value);
  }
  return api_key;
};

/**
 * Adds messages array to Firestore database in document labeled with id.
 * Uses `${id} (1)` (and so on) if id is already in use in database.
 *
 * @param id - Unique identifier for this chat
 * @param messages - Messages array to be submitted to database
 */
const submit_messages = async (id, messages) => {
  var numTries = 0;
  while (true) {
    const doc_id = id + (numTries > 0 ? ` (${numTries})` : "");
    console.log(`trying ${doc_id}`);
    try {
      await setDoc(doc(db, "chats", doc_id), {
        id,
        messages,
      });
      return "";
    } catch (error) {
      numTries++;
      if (numTries >= constants.MAX_DUPLICATES_ALLOWED) {
        return error;
      }
    }
  }
};

/**
 * Processes the response from the OpenAI GPT model and updates the messages array.
 *
 * @async
 * @function
 * @param {Array<{ text: string, sender: string, timestamp: int }>} msgs - The current array of messages (sender should be "ChatGPT" for messages from GPT).
 * @param {Function} setMsgs - A function to update the messages array.
 * @param {Date} startTime - A date object from the beginning of this chat (for computing timestamps).
 * @param {string} API_KEY - The API key for accessing the OpenAI API.
 * @param {Function} then - A callback function to be executed after processing the response.
 * @param {string} [model="gpt-3.5-turbo"] - The GPT model to use. Defaults to "gpt-3.5-turbo".
 * @param {string} [system_message="I'm a helpful assistant."] - The system message provided to the GPT model.
 */
async function processGPTResponse(
  msgs,
  setMsgs,
  startTime,
  API_KEY,
  then,
  model = "gpt-3.5-turbo",
  system_message = "I'm a helpful assistant."
) {
  var messages_array = [
    ...msgs,
    {
      text: "",
      sender: "ChatGPT",
      timestamp: getRelativeTime(startTime),
    },
  ];
  setMsgs(messages_array);

  const apiMessages = msgs.map((msg) => {
    const role = msg.sender === "ChatGPT" ? "assistant" : "user";
    return {
      role,
      content: msg.text,
    };
  });

  const apiRequestBody = {
    model: model,
    messages: [
      {
        role: "system",
        content: system_message,
      },
      ...apiMessages,
    ],
    stream: true,
  };

  // Thinking time
  await sleep(constants.THINKING_TIME * 1000);

  ////////////////////////////////////////////////////////////////////
  // Copied from "https://www.builder.io/blog/stream-ai-javascript"

  // Fetch the response from the OpenAI API with the signal from AbortController
  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${API_KEY}`,
    },
    body: JSON.stringify(apiRequestBody),
  });

  // Read the response as a stream of data
  const reader = response.body.getReader();
  const decoder = new TextDecoder("utf-8");

  var left_over = "";
  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }
    // Massage and parse the chunk of data
    const chunk = left_over + decoder.decode(value).trim();
    console.log(chunk);
    const lines = chunk.split("\n");

    const filteredLines = lines
      .map((line) => line.replace(/^data: /, "").trim()) // Remove the "data: " prefix
      .filter((line) => line !== "" && line !== "[DONE]"); // Remove empty lines and "[DONE]"

    if (filteredLines.length == 0) {
      continue;
    }

    if (filteredLines[filteredLines.length - 1].slice(-2) != "]}") {
      left_over = filteredLines.pop();
    } else {
      left_over = "";
    }

    const parsedLines = filteredLines.map((line) => JSON.parse(line));

    for (const parsedLine of parsedLines) {
      const { choices } = parsedLine;
      const { delta } = choices[0];
      const { content } = delta;
      // Update the UI with the new content
      if (content) {
        messages_array = [
          ...messages_array.slice(0, -1),
          {
            text: messages_array.splice(-1)[0].text + content,
            sender: "ChatGPT",
            timestamp: getRelativeTime(startTime),
          },
        ];
        setMsgs(messages_array);
      }
    }
  }

  then();
}

export { get_api_key, processGPTResponse, submit_messages };

// Async sleep function
const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));
