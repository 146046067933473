import { Container } from "@mui/material";

const EndingPage = () => {
  return (
    <Container maxWidth="md" style={{ textAlign: "center" }}>
      <p style={{ textAlign: "center" }}>
        Please return to Qualtrics!
      </p>
    </Container>
  );
};

export default EndingPage;
