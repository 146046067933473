import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, TextField } from "@mui/material";
import { get_api_key } from "../helper/api";

const StarterPage = () => {
  // State variables
  const [studentID, setSID] = useState("");
  const [passcode, setPasscode] = useState("");
  const navigate = useNavigate();

  /**
   * Initiates the chat application by obtaining the ChatGPT API key using the provided passcode.
   * If passcode is correct, navigates to ChatApp page, otherwise, an alert is shown.
   */
  const begin = async () => {
    if (studentID == ""){
      alert("Must enter student ID");
      return;
    }
    const API_KEY = await get_api_key(passcode);
    if (API_KEY == "Wrong passcode") {
      alert("Wrong passcode");
      return;
    }
    navigate("/chat-app", { state: { studentID, API_KEY } });
  };

  /**
   * Allows user to submit passcode by hitting Enter.
   */
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      begin();
    }
  };

  return (
    <Container maxWidth="md" style={{ textAlign: "center" }}>
      {/* <p style={{ textAlign: "left" }}>
        Here is a description of the study we will be conducting along with
        instructions for how to interact with the chatbot. Here is some more
        text just to mimic how a long paragraph of text will look here. All this
        can be reformatted if desired. This is just some placeholder text. This
        is just some placeholder text.
      </p> */}
      <TextField
        label="Enter Student ID"
        value={studentID}
        onChange={(e) => {
          setSID(e.target.value);
        }}
        style={{
          marginBottom: "10px"
        }}
      />
      <br/>
      <TextField
        label="Enter Passcode"
        value={passcode}
        onChange={(e) => {
          setPasscode(e.target.value);
        }}
        onKeyDown={handleKeyDown}
        style={{
          marginBottom: "10px"
        }}
      />
      <br/>
      <Button
        variant="contained"
        color="primary"
        onClick={begin}
      >
        Begin
      </Button>
    </Container>
  );
};

export default StarterPage;
