import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ChatApp from "./ChatApp";
import StarterPage from "./StarterPage";
import EndingPage from "./EndingPage";
import nature_image from "../resources/nature_image.png";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={nature_image}
          alt="Image"
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "10px",
            marginBottom: "-10px",
            width: "8%"
          }}
        />
        <h2 style={{ textAlign: "center" }}>Sam</h2>
      </header>
      <BrowserRouter>
        <Routes>
          <Route path="/chat-app" element={<ChatApp />} />
          <Route path="/" element={<StarterPage />} />
          <Route path="/done" element={<EndingPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
