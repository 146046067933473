import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { TextField, Button, Container, Paper } from "@mui/material";
import "../styles/ChatApp.css";
import { processGPTResponse, submit_messages } from "../helper/api";
import * as constants from "../helper/constants";
import { getRelativeTime } from "../helper/helper";

const ChatApp = () => {
  // State variables
  const [messages, setMessages] = useState([
    { text: constants.INITIAL_MESSAGE, sender: "ChatGPT", timestamp: 0 },
  ]);
  const [newMessage, setNewMessage] = useState("");
  const [numUserMessages, setNumUserMessages] = useState(0);
  const [isChatGptResponding, setIsChatGptResponding] = useState(false);
  const [textBoxRows, setTextBoxRows] = useState(1);
  const [startTime, setStartTime] = useState(new Date());
  const messagesRef = useRef(messages);

  // Hooks and router-related variables
  const navigate = useNavigate();
  const { state } = useLocation();
  const { studentID, API_KEY } = state;

  /**
   * Runs when user sends a message. Adds the new message and updates relevant state variables.
   */
  const handleSendMessage = () => {
    if (newMessage.trim() === "" || isChatGptResponding) return;
    setMessages([...messages, { text: newMessage, sender: "user", timestamp: getRelativeTime(startTime) }]);
    setNumUserMessages(numUserMessages + 1);
    setNewMessage("");
    setIsChatGptResponding(true);
    setTextBoxRows(1);
  };

  /**
   * Gets ChatGPT response when user sends a new message
   */
  useEffect(() => {
    if (isChatGptResponding) {
      try {
        processGPTResponse(
          messages,
          setMessages,
          startTime,
          API_KEY,
          () => {
            setIsChatGptResponding(false);
          },
          constants.MODEL,
          constants.SYSTEM_MESSAGE
        );
      } catch (error) {
        setMessages([
          ...messages,
          {
            text: `Error getting GPT response: ${error}`,
            sender: "ChatGPT",
            timestamp: getRelativeTime(startTime)
          },
        ]);
        setIsChatGptResponding(false);
      }
    }
  }, [numUserMessages]);

  /**
   * Backs up messages to Firebase and navigates to EndingPage when time is up.
   */
  useEffect(() => {
    setStartTime(new Date());
    const timeoutID = setTimeout(async () => {
      console.log("Time over");
      const error = await submit_messages(studentID, messagesRef.current);
      if (error != "")
        alert(`Error: ${error}`)
      navigate("/done");
    }, constants.CHAT_TIME * 60000);

    return () => { clearTimeout(timeoutID); };
  }, []);

  const getMessages = () => { return messages; }

  const messagesContainer = useRef(null);
  /**
   * Scrolls to bottom whenever a new message is added.
   */
  useEffect(() => {
    if (messagesContainer.current) {
      messagesContainer.current.scrollTop =
        messagesContainer.current.scrollHeight;
    }
  }, [messages]);

  /**
   * Allow user to send message by hitting Enter
   */
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };

  /**
   * Records user input in textfield and dynamically resizes textfield as necessary.
   */
  const handleTextFieldChange = (event) => {
    setNewMessage(event.target.value);
    const lineHeight = 23;
    const newRows = Math.ceil(event.target.scrollHeight / lineHeight);
    setTextBoxRows(newRows);
  };

  /**
   * Update messagesRef when messages changes.
   */
  useEffect(() => {
    messagesRef.current = messages;
    console.log(messages);
  }, [messages]);

  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: "20px", margin: "20px 0" }}>
        <div
          ref={messagesContainer}
          style={{
            height: "300px",
            overflowY: "scroll",
            marginBottom: "10px",
            paddingRight: "10px",
          }}
        >
          {messages.map((message, index) => (
            <div
              key={index}
              style={{ textAlign: "left", marginBottom: "10px" }}
            >
              <strong>{message.sender === "user" ? "You" : "Sam"}:</strong>{" "}
              {message.text}
            </div>
          ))}
        </div>
        <TextField
          label="Type your message"
          fullWidth
          multiline
          rows={textBoxRows}
          value={newMessage}
          onChange={handleTextFieldChange}
          onKeyDown={handleKeyDown}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendMessage}
          style={{ marginTop: "10px" }}
          disabled={isChatGptResponding}
        >
          Send
        </Button>
      </Paper>
    </Container>
  );
};

export default ChatApp;
